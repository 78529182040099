.information-header{
    margin-left: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 14px !important;
}
/* .information-header-text{
    
} */

.information-header-info{
    position: relative;
    right: 0;
    margin-right: 30px;
}

.shipping-list{
    font-size: 6px;
}

.loading-container{
    display: flex;
    margin-top: 100px;
    justify-content: center;
}