.nice-dates-day.-highlight { color: orange; }
.nice-dates-day.-nextDelivery { 
  background-color: #838949; 
  color: rgb(255, 255, 255); 
  border-radius: 30px;
}

.nice-dates-day.-suspended { 
  background-color: #e92f2f; 
  color: rgb(255, 255, 255); 
  border-radius: 30px;
}
/* 
.nice-dates-day.-selected { 
  color: black; 
}

.nice-dates-day:before{
  border-color: #838949;
  border: #838949 solid 2px;
  background-color: #ffffff; 
} */